import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import { Container, Row, Col } from "react-bootstrap"

import Seo from "../components/common/seo"
import Layout from "../components/common/layout"

import Testimonials from "../components/testimonials"

const ClientsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        clients {
          image_alt {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 60
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Clientes" />
      <div>
        <StaticImage src="../images/banner.png" layout="fullWidth" alt="" placeholder="blurred" />
      </div>
      <Container className="half-padding-top half-padding-bottom clients-page">
        <Row>
          <Col className="mb-3">
            <div className="title">
              <h3 className="gradient-text">Clientes</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Mais do que conquistar clientes, desenvolvemos relações de
              parceria e amizade. Mais do que empresas, são profissionais de
              diversas áreas, como engenharia, TI, diretoria e marketing que
              confiam no nosso método de trabalho e na nossa missão de entregar
              soluções tecnológicas eficientes e confiáveis alinhadas aos seus
              negócios. Temos a honra de contar com a confiança de empresas de
              todos os portes dos setores público e privado.
            </p>
          </Col>
        </Row>
        <Row>
          {data.api.clients.map((client, idx) => {
            if (!client.image_alt) {
              return null
            }
            return (
              <Col md={4} xs={6} key={`client-${idx}`}>
                <div className="d-flex justify-content-center align-items-center client-logo">
                  <GatsbyImage
                    image={
                      client.image_alt.imageFile.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
      <div className="white-bg half-padding-top half-padding-bottom">
        <Container>
          <Testimonials />
        </Container>
      </div>
    </Layout>
  )
}

export default ClientsPage
